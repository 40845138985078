import {EmployeeWorkspace, Workspace, WorkspaceBookType, WorkspaceStatuses} from '@shared/core';
import {areIntervalsOverlapping, endOfDay, format, parse, startOfDay} from 'date-fns';
import {BookingRange} from '../hooks/useCalculatedBookingRanges';

export const API_DATE_FORMAT = 'dd.MM.yyyy';

interface WorkspaceIsTakenProps {
  isWorkspaceLoading: boolean;
  workspace: Workspace;
  workspaceStatus: WorkspaceBookType;
  selectedRange: [Date, Date];
  userId: string;
}

export const checkWorkspaceIsTaken = ({
  isWorkspaceLoading,
  workspace,
  workspaceStatus,
  selectedRange,
  userId,
}: WorkspaceIsTakenProps) => {
  if (isWorkspaceLoading && !workspace) return false;
  const {status, employee, comment, bookedStatus} = workspace;
  const [formStartDate, formEndDate] = selectedRange || [null, null];

  if (status === WorkspaceStatuses.Occupied && employee.id !== userId) return true;

  if (status === WorkspaceStatuses.Reserved && employee.id === userId) return false;
  if (status === WorkspaceStatuses.Reserved && (employee.id !== userId || comment)) return true;

  if (
    status === WorkspaceStatuses.Booked &&
    (workspaceStatus === WorkspaceStatuses.Occupied || workspaceStatus === WorkspaceStatuses.Reserved)
  )
    return true;

  if (
    status === WorkspaceStatuses.Booked &&
    workspaceStatus === WorkspaceStatuses.Booked &&
    formStartDate &&
    formEndDate
  ) {
    const areBookingDatesOverlaps = bookedStatus.some((booking) => {
      const {startDay, endDay} = booking?.range;

      if (!startDay && !endDay) return false;

      const isOwnedByCurrentUserWithMatchedRange =
        format(formStartDate, API_DATE_FORMAT) === startDay &&
        format(formEndDate, API_DATE_FORMAT) === endDay &&
        userId === booking.employee?.id;

      if (isOwnedByCurrentUserWithMatchedRange) return false;

      const bookedRangeStartDate = startOfDay(parse(startDay, API_DATE_FORMAT, new Date()));
      const bookedRangeEndDate = endOfDay(parse(endDay, API_DATE_FORMAT, new Date()));

      return areIntervalsOverlapping(
        {start: formStartDate, end: formEndDate},
        {start: bookedRangeStartDate, end: bookedRangeEndDate},
        {inclusive: true},
      );
    });

    return areBookingDatesOverlaps;
  }

  return false;
};

interface BookingConflictRangesProps {
  disabledForBookingRanges: BookingRange[];
  selectedRange: [Date, Date];
}

export const getBookingConflictRanges = ({disabledForBookingRanges, selectedRange}: BookingConflictRangesProps) => {
  const [formStartDate, formEndDate] = selectedRange || [null, null];

  if (formStartDate && formEndDate) {
    const conflictRanges = disabledForBookingRanges.filter(({start: bookedRangeStartDate, end: bookedRangeEndDate}) => {
      return areIntervalsOverlapping(
        {start: formStartDate, end: formEndDate},
        {start: bookedRangeStartDate, end: bookedRangeEndDate},
        {inclusive: true},
      );
    });

    return conflictRanges;
  }
};

interface EmployeeTemporaryWorkspaceOverlapsProps {
  workspaces: EmployeeWorkspace[];
  selectedRange: [Date, Date];
  officeId: string;
  workspaceId: string;
}

export const findEmployeeTemporaryWorkspaceOverlaps = ({
  workspaces,
  selectedRange,
  officeId,
  workspaceId,
}: EmployeeTemporaryWorkspaceOverlapsProps) => {
  const [formStartDate, formEndDate] = selectedRange;

  return workspaces.find((workspace) => {
    if (workspace.status === WorkspaceStatuses.Booked) {
      const isSameOffice = workspace.officeId === officeId;
      if (!isSameOffice) return false;

      const isSelectedDatesMatchRange =
        format(formStartDate, API_DATE_FORMAT) === format(new Date(workspace.startDay), API_DATE_FORMAT) &&
        format(formEndDate, API_DATE_FORMAT) === format(new Date(workspace.endDay), API_DATE_FORMAT) &&
        workspace.id === workspaceId;

      if (isSelectedDatesMatchRange) return false;

      const areRangesOverlapping = areIntervalsOverlapping(
        {start: new Date(workspace.startDay), end: new Date(workspace.endDay)},
        {start: formStartDate, end: formEndDate},
        {inclusive: true},
      );

      return areRangesOverlapping;
    }
    return false;
  });
};
