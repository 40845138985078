import {inject, injectable} from 'inversify';
import {EmployeesNormalization, GlobalContainerTypes, Language} from '@innowise-group/core';
import {HistoryNormalization} from './history-normalization.types';
import {HistoryContainerTypes} from '../history-ioc.types';
import {ActionHistoryType, HistoryResponse} from '../history-api';
import {ChangeHistory} from '../../models/change-history.model';

import {actionStatuses} from './action-statuses.constant';

@injectable()
class HistoryNormalizationService implements HistoryNormalization {
  public static readonly type = HistoryContainerTypes.HistoryNormalization;

  @inject(GlobalContainerTypes.EmployeesNormalization) private employeesNormalization: EmployeesNormalization;
  @inject(GlobalContainerTypes.Language) private language: Language;

  constructor() {
    this.normalizeHistoryFromApi = this.normalizeHistoryFromApi.bind(this);
    this.normalizeHistoryActionFromApi = this.normalizeHistoryActionFromApi.bind(this);
  }

  public normalizeHistoryFromApi(history: HistoryResponse): ChangeHistory {
    const {
      id,
      location,
      street,
      employee,
      room,
      created_at,
      location_eng,
      street_eng,
      action,
      workspace_number,
      initiator,
      status,
      office_id,
    } = history;
    return {
      id: id && String(id),
      employee: this.employeesNormalization.normalizeEmployeeFromApi(employee),
      responsible: this.employeesNormalization.normalizeEmployeeFromApi(initiator),
      time: new Date(created_at),
      office: this.language.checkAppLanguage() ? `${location}, ${street}` : `${location_eng}, ${street_eng}`,
      seat: workspace_number,
      room: {
        id: room?.id && String(room?.id),
        number: room?.number,
      },
      action: action && this.normalizeHistoryActionFromApi(action),
      status,
      office_id,
    };
  }

  private normalizeHistoryActionFromApi(action: ActionHistoryType): string {
    if (actionStatuses[action]) return actionStatuses[action];
    return 'unknown';
  }
}

export default HistoryNormalizationService;
