import {RequestStatus, WorkspaceBookType} from '@innowise-group/core';
import {StateColor, Theme} from '@innowise-group/ui-kit';

const getThemeByRequestType = (status: WorkspaceBookType | RequestStatus, theme: Theme): StateColor => {
  switch (status) {
    case 'Reserved':
      return theme.palette.state.warning;
    case 'Booked':
    case 'in-clarification':
      return theme.palette.main;
    case 'Occupied':
      return theme.palette.state.error;
    case 'Remote':
    case 'Coworking':
      return theme.palette.secondary;
    case 'conflict':
      return theme.palette.state.warning;
    case 'rejected':
      return theme.palette.state.error;
    case 'approved':
      return theme.palette.state.success;
    default:
      return theme.palette.state.success;
  }
};

export default getThemeByRequestType;
