import {memo} from 'react';
import * as Styled from './workspace-view.styles';
import {useTranslation} from 'react-i18next';

import {getIconByRequestType} from '@shared/utilities';
import {RequestStatus, WorkspaceBookType} from '@shared/core';

import {Icon} from '@innowise-group/ui-kit';

interface WorkspaceStatusBlockProps {
  status: WorkspaceBookType | RequestStatus;
  color: string;
}

const WorkspaceStatus = ({status, color}: WorkspaceStatusBlockProps) => {
  const {t} = useTranslation();

  return (
    <Styled.WorkspaceStatusBlock>
      <Icon type={getIconByRequestType(status)} size={16} fill="white" background={color} />
      <Styled.WorkspaceStatusText type="h3" textColor={color}>
        {t(`statuses.${status.toLowerCase()}`)}
      </Styled.WorkspaceStatusText>
    </Styled.WorkspaceStatusBlock>
  );
};

export default memo(WorkspaceStatus);
