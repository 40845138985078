import React from 'react';
import {BookedStatus, RequestStatus, WorkspaceBookType} from '@innowise-group/core';
import {useTheme} from '@innowise-group/ui-kit';
import {getThemeByRequestType} from '@innowise-group/utilities';
import WorkspaceBookingInfo from './workspace-booking-info.component';
import WorkspaceStatus from './workspace-status.component';

interface WorkspaceEmployeesListProps {
  workspaces: BookedStatus[];
  status: WorkspaceBookType | RequestStatus;
}

const WorkspaceEmployeesList: React.FC<WorkspaceEmployeesListProps> = ({workspaces, status}) => {
  const theme = useTheme();
  const color = getThemeByRequestType(status, theme);

  return (
    <>
      <WorkspaceStatus status={status} color={color.default} />
      {workspaces.map(({id, employee, range}) => (
        <WorkspaceBookingInfo key={id} employee={employee} range={range} />
      ))}
    </>
  );
};

export default React.memo(WorkspaceEmployeesList);
