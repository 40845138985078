import {EmployeesNormalization} from './employees-normalization.types';
import {
  Employee,
  EmployeeResponse,
  EmployeeWorkspace,
  EmployeeWorkspaceResponse,
  GlobalContainerTypes,
  ManagementStaffResponse,
  ListOfficeResponse,
  ManagementStaff,
  StaffOffice,
  inject,
  Language,
} from '@innowise-group/core';
import {injectable} from 'inversify';
import withoutPhoto from '../../../../assets/icons/unknown-user-logo.svg';

@injectable()
class EmployeesNormalizationService implements EmployeesNormalization {
  public static readonly type = GlobalContainerTypes.EmployeesNormalization;
  @inject(GlobalContainerTypes.Language) private language: Language;

  constructor() {
    this.normalizeEmployeeFromApi = this.normalizeEmployeeFromApi.bind(this);
    this.getEmployeeName = this.getEmployeeName.bind(this);
    this.normalizeEmployeeWorkspace = this.normalizeEmployeeWorkspace.bind(this);
    this.normalizeManagementStaffFromApi = this.normalizeManagementStaffFromApi.bind(this);
    this.normalizeManagementStaffOffices = this.normalizeManagementStaffOffices.bind(this);
  }

  public normalizeEmployeeFromApi(employee: EmployeeResponse): Employee {
    const permanentWorkspaces = employee?.workspaces || [];
    const temporalWorkspaces =
      employee?.booked_workspaces?.map(({id, workspace, occupation_start_date, occupation_end_date}) => ({
        ...workspace,
        occupation_start_date,
        occupation_end_date,
        bookingId: id,
      })) || [];

    return {
      id: employee?.id && String(employee?.id),
      hrm_id: employee?.hrm_id && String(employee?.hrm_id),
      firstName: employee?.name_eng,
      lastName: employee?.surname_eng,
      department: employee?.department,
      division: employee?.division,
      unit: employee?.unit,
      email: employee?.email,
      photoUrl: employee?.photo || withoutPhoto,
      fullName: employee && this.getEmployeeName(employee),
      workspaces: this.normalizeEmployeeWorkspace([...permanentWorkspaces, ...temporalWorkspaces]),
      isHaveWorkspace: employee?.is_have_workspace,
      jiraOfficeId: employee?.jira_office_id && String(employee?.jira_office_id),
    };
  }

  public normalizeManagementStaffFromApi(employee: ManagementStaffResponse): ManagementStaff {
    return {
      ...this.normalizeEmployeeFromApi({
        id: employee?.id,
        hrm_id: employee?.hrm_id,
        email: employee?.email,
        photo: employee?.photo,
        name_eng: employee?.name_eng,
        surname_eng: employee?.surname_eng,
        preferred_name: employee?.preferred_name,
        department: employee?.department,
        division: employee?.division,
        unit: employee?.unit,
      }),
      managedOffices: this.normalizeManagementStaffOffices(employee?.managed_offices),
      hrOffices: this.normalizeManagementStaffOffices(employee?.office_hr),
    };
  }

  private getEmployeeName(employee: EmployeeResponse): string {
    const {preferred_name, surname_eng, name_eng} = employee;
    const fullName = preferred_name ? preferred_name : `${surname_eng} ${name_eng}`;
    return this.language.checkAppLanguage() ? fullName : `${surname_eng} ${name_eng}`;
  }

  private normalizeEmployeeWorkspace(workspaces: EmployeeWorkspaceResponse[] = []): EmployeeWorkspace[] {
    return workspaces.length
      ? workspaces.map(
          ({
            id,
            hrm_id,
            floor_id,
            floor,
            office_address,
            office_id,
            number,
            room,
            city,
            status,
            office_address_eng,
            city_eng,
            occupation_start_date,
            occupation_end_date,
            bookingId,
          }) => ({
            id: id && String(id),
            hrm_id: hrm_id && String(hrm_id),
            floorId: floor_id && String(floor_id),
            room,
            number: number && String(number),
            floor:
              status === 'Remote'
                ? this.language.checkAppLanguage()
                  ? 'виртуальном'
                  : 'virtual'
                : floor && String(floor),
            city: this.language.checkAppLanguage() ? city : city_eng,
            address: this.language.checkAppLanguage() ? office_address : office_address_eng,
            status,
            officeId: office_id && String(office_id),
            startDay: occupation_start_date,
            endDay: occupation_end_date,
            ...(bookingId ? {bookingId: bookingId} : null),
          }),
        )
      : [];
  }

  private normalizeManagementStaffOffices(offices: ListOfficeResponse[]): StaffOffice[] {
    return offices.map(({id, address, address_eng, city_eng, postcode, location, owner, city, country}) => ({
      id: id && String(id),
      locationId: location && String(location),
      owner: owner && String(owner),
      postcode: postcode,
      address: this.language.checkAppLanguage() ? address : address_eng,
      location: `${country}, ${this.language.checkAppLanguage() ? city : city_eng}`,
    }));
  }
}

export default EmployeesNormalizationService;
