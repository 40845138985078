import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useParams} from 'react-router-dom';
import {FloorsApiService, useService} from '@shared/core';
import {Typography} from '@innowise-group/ui-kit';

import * as Styled from './floor-selector.styles';
import {SearchFloorValues} from '../search-floor-form';

export interface FloorSelectorProps {
  officeId?: string;
  disabled?: boolean;
  onFloorChanged?: (values: SearchFloorValues) => void;
  secondFloorId?: string;
}

const usePrevious = (value) => {
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  });

  return ref.current;
};

const FloorSelector: React.FC<FloorSelectorProps> = ({officeId, disabled, onFloorChanged, secondFloorId}) => {
  const [allFloors, setAllFloors] = useState([]);
  const {id: floorId} = useParams();
  const floorsApi = useService(FloorsApiService);
  const previousOfficeId = usePrevious(officeId);
  const previousFirstFloorId = usePrevious(floorId);
  const previousSecondFloorId = usePrevious(secondFloorId);

  useEffect(() => {
    const subscriber = floorsApi.getListFloors().subscribe(setAllFloors);
    return () => subscriber.unsubscribe();
  }, [floorsApi]);

  const officeFloors = useMemo(() => {
    const floorIdChosen = officeId ? floorId : secondFloorId;
    const officeIdChosen = officeId ? officeId : allFloors.find((floor) => floor.id === secondFloorId)?.office;

    if (floorIdChosen && officeIdChosen) {
      const isFloorVirtual = allFloors.find((floor) => floor.id === floorIdChosen)?.isVirtual;
      const floorsByOfficeId = allFloors.filter(
        (floor) => floor.office === officeIdChosen && floor.isVirtual === isFloorVirtual,
      );

      return floorsByOfficeId;
    }

    return [];
  }, [allFloors, floorId, officeId, secondFloorId]);

  const changeFloor = useCallback(
    (floorId: string) => {
      if (typeof onFloorChanged === 'function') {
        onFloorChanged({floor: floorId} as SearchFloorValues);
      }
    },
    [onFloorChanged],
  );

  useEffect(() => {
    const condition =
      !secondFloorId &&
      !disabled &&
      previousOfficeId !== officeId &&
      Array.isArray(officeFloors) &&
      officeFloors.length > 0;

    if (condition) {
      changeFloor(officeFloors[0].id);
    }
  }, [previousOfficeId, officeId, changeFloor, officeFloors, disabled, secondFloorId]);

  const handleFloorChanged = useCallback(
    (event) => {
      const {floorId} = event.currentTarget.dataset;

      if (
        disabled ||
        previousFirstFloorId === floorId ||
        (previousSecondFloorId && previousSecondFloorId === floorId)
      ) {
        return;
      }

      changeFloor(floorId);
    },
    [changeFloor, disabled, previousFirstFloorId, previousSecondFloorId],
  );

  if (!Array.isArray(officeFloors) || !officeFloors?.length) {
    return null;
  }

  return (
    <Styled.ListContainer className={disabled && 'disabled'}>
      {officeFloors.map((floor) => (
        <Styled.ListItem
          key={floor.id}
          active={secondFloorId ? floor.id === secondFloorId : floor.id === floorId}
          data-floor-id={floor.id}
          className={disabled && 'disabled'}
          onClick={handleFloorChanged}
        >
          <Typography>{floor.number}</Typography>
        </Styled.ListItem>
      ))}
    </Styled.ListContainer>
  );
};

export default React.memo(FloorSelector);
