import React, {useContext} from 'react';
import {useTranslation} from 'react-i18next';
import {BookedStatus, Employee, RequestStatus, WorkspaceBookType, WorkspaceStatuses} from '@innowise-group/core';
import * as Styled from './workspace-view.styles';
import WorkspaceBookingInfo from './workspace-booking-info.component';
import WorkspaceEmployeesList from './workspace-employees-list.component';
import {FloorData} from '../../pages/floor-details/use-plan-details.hook';
import {FloorContext} from '../../components/floor-plan/floor-plan.component';
import WorkspaceStatus from './workspace-status.component';

interface WorkspaceInfoProps {
  status: WorkspaceBookType | RequestStatus;
  employee: Employee;
  color: string;
  range: {
    startDay: string;
    endDay: string;
  };
  bookedStatus?: BookedStatus[];
  activeRequests?: BookedStatus[];
  onBookWorkspaceClick: () => void;
  showOnPlan?: boolean;
  comment?: string;
  floor?: FloorData;
}

const isFree = (workspace: WorkspaceInfoProps) =>
  workspace.status === WorkspaceStatuses.Free && !workspace.activeRequests.length;

const hasTemporaryRequest = (workspace: WorkspaceInfoProps) => {
  const hasActiveRequest = (workspace) => !!(workspace?.activeRequests || []).length;
  const isTemporaryRequest = (request: BookedStatus) => request.range.startDay && request.range.endDay;
  return hasActiveRequest(workspace) && isTemporaryRequest(workspace.activeRequests[0]);
};

const isTemporaryBooked = (workspace: WorkspaceInfoProps) => workspace.status === WorkspaceStatuses.Booked;

const isOccupied = (workspace: WorkspaceInfoProps) =>
  workspace.status === WorkspaceStatuses.Occupied || workspace.status === WorkspaceStatuses.Reserved;

const WorkspaceInfo: React.FC<WorkspaceInfoProps> = (workspace) => {
  const {t} = useTranslation();

  const {
    status,
    employee,
    color,
    range,
    bookedStatus = [],
    activeRequests = [],
    onBookWorkspaceClick,
    showOnPlan = false,
    comment,
    floor,
  } = workspace;

  const {floorsEventEmitter} = useContext(FloorContext);
  return (
    <Styled.WorkspaceInfoContainer>
      {activeRequests.length > 0 && <WorkspaceEmployeesList workspaces={activeRequests} status="in-clarification" />}
      {bookedStatus.length > 0 && <WorkspaceEmployeesList workspaces={bookedStatus} status="Booked" />}
      {status !== WorkspaceStatuses.Booked && status !== WorkspaceStatuses.Free && (
        <>
          <WorkspaceStatus status={status} color={color} />
          <WorkspaceBookingInfo employee={employee} range={range} comment={comment} />
        </>
      )}
      {isFree(workspace) && <WorkspaceStatus status={status} color={color} />}
      {!isOccupied(workspace) &&
        (isFree(workspace) || hasTemporaryRequest(workspace) || isTemporaryBooked(workspace)) &&
        !showOnPlan && (
          <Styled.BookButton
            disabled={!floor.floor || !floorsEventEmitter.isClientReady}
            onClick={onBookWorkspaceClick}
            icon="u_bookmark"
            iconSize={16}
            withLoader={!floorsEventEmitter.isClientReady}
          >
            {t('buttons.book')}
          </Styled.BookButton>
        )}
    </Styled.WorkspaceInfoContainer>
  );
};

export default React.memo(WorkspaceInfo);
