import {Observable} from 'rxjs';
import {
  EmployeeResponse,
  RoomType,
  Workspace,
  WorkspaceCoordinate,
  AvailableRoomsAndWorkspaces,
} from '@innowise-group/core';

export type WorkspaceBookType = 'Booked' | 'Occupied' | 'Reserved' | 'Free' | 'Remote' | 'Coworking';

export interface WorkspacesApi {
  editWorkspace: (workspaceId: string, workspaceData: object) => Observable<unknown>;
  deleteWorkspaces: (workspaceId: Array<string>) => Observable<unknown>;
  createWorkspaces: (workspaces: WorkspaceToCreate[]) => Observable<void>;
  updateWorkspaces: (workspaces: WorkspaceToUpdate[]) => Observable<void>;
  bookingWorkspaceMode: (floorId: string, start: string, end: string) => Observable<Workspace[]>;
  getWorkspaces: () => Observable<Workspace[]>;
  getWorkspaceById: (id: string) => Observable<Workspace>;
  getWorkspacesFromFloor: (id: string) => Observable<Workspace[]>;
  getAvailableRoomsAndWorkspaces: (
    floorId: string,
    startDate: string,
    endDate: string,
  ) => Observable<AvailableRoomsAndWorkspaces[]>;
}

export interface BookedStatusResponse {
  id: number;
  workspace: number;
  employee: EmployeeResponse;
  occupation_end_date: string;
  occupation_start_date: string;
  comment: string;
}

export interface WorkspaceResponse {
  employee: EmployeeResponse;
  id: number;
  number: number;
  occupation_end_date: string;
  occupation_start_date: string;
  occupation_type: null;
  room: number;
  room_number: string;
  room_type: RoomType;
  status: WorkspaceBookType;
  comment: string;
  coordinates: WorkspaceCoordinate;
  booked_status: BookedStatusResponse[];
  active_requests: BookedStatusResponse[];
  blue?: boolean;
  rotate_degree: number;
}

export interface WorkspaceToCreate {
  employee: number;
  number: number;
  occupation_end_date: string;
  occupation_start_date: string;
  occupation_type?: string;
  room: number;
  status: WorkspaceBookType;
  comment: string;
  coordinates: WorkspaceCoordinate;
  rotate_degree: number;
}

export interface WorkspaceToUpdate extends WorkspaceToCreate {
  id: number;
}

export enum WorkspaceStatuses {
  Booked = 'Booked',
  Occupied = 'Occupied',
  Reserved = 'Reserved',
  Free = 'Free',
  Remote = 'Remote',
  Coworking = 'Coworking',
}
