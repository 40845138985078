import {RequestStatus} from '@innowise-group/core';
import {Theme} from '@innowise-group/ui-kit';

export const getColorByRequestType = (
  type: RequestStatus,
  {
    palette: {
      general: {darkGrey},
      state: {error, success, warning},
      main,
    },
  }: Theme,
): string => {
  switch (type) {
    case 'approved':
      return success.default;
    case 'in-clarification':
      return main.default;
    case 'conflict':
      return warning.default;
    case 'rejected':
      return error.default;
    case 'delayed':
      return darkGrey[60];
    case 'canceled':
      return darkGrey[60];
  }
};
