import {Icon} from '@innowise-group/ui-kit';
import {Workspace, WorkspaceBookType, WorkspaceStatuses} from '@innowise-group/core';
import React, {useMemo} from 'react';
import * as Styled from './workspace-view.styles';

interface WorkspaceActionsProps {
  onClockwiseRotateClick?: () => void;
  onAntiClockwiseRotateClick?: () => void;
  onEditClick: () => void;
  onDeleteClick: () => void;
  onCopyWorkspaceClick?: () => void;
  status: WorkspaceBookType;
  workspace: Workspace;
  copyWorkspaceIcon: boolean;
}

const WorkspaceActions: React.FC<WorkspaceActionsProps> = ({
  onClockwiseRotateClick,
  onAntiClockwiseRotateClick,
  onEditClick,
  onDeleteClick,
  onCopyWorkspaceClick,
  status,
  workspace,
  copyWorkspaceIcon,
}) => {
  const onDragStartHandler = (event: React.DragEvent<HTMLDivElement>, workspace: Workspace) => {
    event.dataTransfer.setData('workspace', JSON.stringify(workspace));
    event.dataTransfer.effectAllowed = 'move';
  };

  const isDragEmployee = useMemo(() => {
    return (
      (status === WorkspaceStatuses.Occupied || status === WorkspaceStatuses.Reserved) && workspace?.employee?.fullName
    );
  }, [status, workspace]);

  return (
    <Styled.WorkspaceActionsContainer>
      {isDragEmployee && (
        <Styled.UserPhoto
          margin={0}
          imageUrl={workspace?.employee?.photoUrl}
          draggable={true}
          onDragStart={(event) => onDragStartHandler(event, workspace)}
        />
      )}
      <Styled.WorkspaceActionsBlock>
        {onClockwiseRotateClick && <Icon type="u_corner-up-right" onClick={onClockwiseRotateClick} isPointer isHover />}
        {onAntiClockwiseRotateClick && (
          <Icon type="u_corner-up-left" onClick={onAntiClockwiseRotateClick} isPointer isHover />
        )}
        {copyWorkspaceIcon && <Icon type="u_copy" onClick={onCopyWorkspaceClick} isPointer isHover />}
        <Icon type="u_edit-alt" onClick={onEditClick} isPointer isHover />
        <Icon type="u_trash-alt" onClick={onDeleteClick} isPointer isHover />
      </Styled.WorkspaceActionsBlock>
    </Styled.WorkspaceActionsContainer>
  );
};

export default React.memo(WorkspaceActions);
