import {EmployeeWorkspace} from '@innowise-group/core';
import {endOfDay, isWithinInterval, startOfDay} from 'date-fns';

enum StatusPrecedence {
  Occupied,
  Reserved,
  Remote,
}
const BOOKED = 'Booked';

export default function sortEmployeeWorkspaces(workspaces: EmployeeWorkspace[]) {
  const today = new Date();

  const todayBookedWorkspaces: EmployeeWorkspace[] = [];
  const otherWorkspaces: EmployeeWorkspace[] = [];

  workspaces.forEach((workspace) => {
    if (workspace.status === BOOKED) {
      const startDay = startOfDay(new Date(workspace.startDay));
      const endDay = endOfDay(new Date(workspace.endDay));

      if (isWithinInterval(today, {start: startDay, end: endDay})) {
        todayBookedWorkspaces.push(workspace);
        return;
      }
    }
    otherWorkspaces.push(workspace);
  });

  const sortedTodayBookedWorkspaces = [...todayBookedWorkspaces].sort(
    (workspaceCurrent, workspaceNext) =>
      new Date(workspaceNext.startDay).getTime() - new Date(workspaceCurrent.startDay).getTime(),
  );

  const sortedOtherWorkspaces = [...otherWorkspaces].sort(
    (workspaceCurrent, workspaceNext) =>
      StatusPrecedence[workspaceCurrent.status] - StatusPrecedence[workspaceNext.status],
  );

  return [...sortedTodayBookedWorkspaces, ...sortedOtherWorkspaces];
}
