import {Controller, useFormContext} from 'react-hook-form';
import {FC, ChangeEvent, useCallback, useEffect, useState, useMemo, useRef} from 'react';
import {RequestType} from '@shared-components';
import {format} from 'date-fns';
import {finalize, Observable} from 'rxjs';
import {useTranslation} from 'react-i18next';
import {validateDate} from '@innowise-group/utilities';

import {
  Avatar,
  Button,
  CustomDatePicker,
  Divider,
  Form,
  Icon,
  InformationTile,
  useTheme,
  withForm,
} from '@innowise-group/ui-kit';

import {
  ApproveWorkspaceRequestPayload,
  Employee,
  EmployeesApiService,
  Request,
  Workspace,
  WorkspaceBookType,
  WorkspaceStatuses,
  WorkspacesApiService,
  useService,
} from '@innowise-group/core';

import * as Styled from './seat-request.styles';
import useCalculatedBookingRanges from '../../hooks/useCalculatedBookingRanges';
import withSeatRequestDetails from './with-seat-request-details.hoc';
import withShowOnPlan from './with-show-on-plan.hoc';
import {EditRequestValueType} from '../../services/request-modals-facade';
import {getColorByRequestType} from '../../utilities/get-color-by-request-type.utility';
import {getIconByRequestType} from '../../utilities/get-icon-by-request-type.utility';
import {internationalizeStatus} from '../../utilities/internationalize-status.utility';
import {
  getBookingConflictRanges,
  checkWorkspaceIsTaken,
  findEmployeeTemporaryWorkspaceOverlaps,
} from '../../utilities/workspace-validation-in-request.utility';

export interface SeatRequestProps {
  requestId: string;
  data: Request;
  approveRequest: (payload: ApproveWorkspaceRequestPayload) => void;
  rejectRequest: (comment: string) => void;
  editRequest: (data: EditRequestValueType) => void;
  deleteRequest: () => void;
  cancelRequest: () => void;
  showOnPlanHandler?: (workspace?: Workspace & {floorId: string}) => void;
  person?: Employee;
  confirmationModal?: (
    title: string,
    text: string,
    confirmationText: string,
    cancelText: string,
    onComplete?: () => void,
  ) => Observable<void>;
}

enum SeatRequestFormFields {
  WorkspaceStatus = 'workspaceStatus',
  DateRange = 'dateRange',
}

const statusByRequestType: {[x in RequestType]: WorkspaceBookType} = {
  [RequestType.TemporalOccupation]: 'Booked',
  [RequestType.FullOccupation]: 'Occupied',
};

interface SeatRequestForm {
  [SeatRequestFormFields.WorkspaceStatus]: WorkspaceBookType;
  [SeatRequestFormFields.DateRange]: [Date, Date];
}

const SeatRequest: FC<SeatRequestProps> = ({
  data,
  approveRequest,
  rejectRequest,
  deleteRequest,
  editRequest,
  showOnPlanHandler,
  person,
  cancelRequest,
  confirmationModal,
}) => {
  const {
    seat,
    status,
    office,
    range,
    floor,
    comment,
    managerComment,
    created,
    room,
    user,
    personalRequest,
    id,
    officeManager,
    requestType,
  } = data;

  const {t} = useTranslation();
  const theme = useTheme();
  const [value, setValue] = useState('');

  const {
    control,
    watch,
    getValues,
    handleSubmit,
    setValue: setFormValue,
    trigger,
    formState: {errors, isValid},
  } = useFormContext<SeatRequestForm>();

  const color = getColorByRequestType(status, theme);
  const workspacesApi = useService(WorkspacesApiService);
  const employeesApi = useService(EmployeesApiService);

  const [approveInProgress, setApproveInProgress] = useState(false);
  const [associatedWorkspace, setAssociatedWorkspace] = useState<Workspace>();
  const [isAssociatedWorkspaceLoading, setAssociatedWorkspaceLoading] = useState(true);

  const currentUserId = user?.id;
  const subscriptionRef = useRef(null);

  //clean up subscription on unmount
  useEffect(() => {
    return () => {
      if (subscriptionRef.current) {
        subscriptionRef.current.unsubscribe();
      }
    };
  }, []);

  // Load associated data to find information about booking
  useEffect(() => {
    setAssociatedWorkspaceLoading(true);

    const subscription = workspacesApi.getWorkspaceById(seat.id).subscribe((data) => {
      setAssociatedWorkspace(data);
      setAssociatedWorkspaceLoading(false);
    });

    return () => subscription.unsubscribe();
  }, [workspacesApi, seat, status]);

  const selectedRange = watch(SeatRequestFormFields.DateRange);
  const disabledForBookingRanges = useCalculatedBookingRanges({associatedWorkspace, currentUserId});
  const workspaceStatus = watch(SeatRequestFormFields.WorkspaceStatus);

  const bookingRangeConflictWithAlreadyBookedRanges = useMemo(() => {
    if (isAssociatedWorkspaceLoading) {
      return null;
    }
    return getBookingConflictRanges({disabledForBookingRanges, selectedRange});
  }, [isAssociatedWorkspaceLoading, selectedRange, disabledForBookingRanges]);

  const isBookingRangeCouldConflictWithAlreadyBookedRanges =
    (bookingRangeConflictWithAlreadyBookedRanges || []).length > 0;

  const editRequestHandler = () => {
    editRequest({
      seat: seat?.id,
      range,
      floor: floor?.id,
      room: room?.id,
      office: office?.id,
      comment,
    });
  };

  const approveRequestHandler = () => {
    setApproveInProgress(true);

    const {workspaceStatus, dateRange} = getValues();

    const payload: ApproveWorkspaceRequestPayload = {
      workspace: Number(seat.id),
      workspace_type: workspaceStatus,
      manager_comment: value,
    };

    if (workspaceStatus === WorkspaceStatuses.Booked) {
      const [start, end] = dateRange;
      payload.occupation_start_date = format(start, 'yyyy-MM-dd');
      payload.occupation_end_date = format(end, 'yyyy-MM-dd');
    }

    subscriptionRef.current = employeesApi
      .getEmployeeById(user.id)
      .pipe(finalize(() => setApproveInProgress(false)))
      .subscribe((employeeData) => {
        const permanentWorkspace = employeeData.workspaces.find(
          (workspace) =>
            workspace.status === WorkspaceStatuses.Occupied &&
            (workspace.officeId === office.id || workspaceStatus === WorkspaceStatuses.Occupied),
        );

        if (permanentWorkspace && workspaceStatus !== WorkspaceStatuses.Reserved) {
          return confirmationModal(
            t('modals.attention'),
            t('modals.managerPermanentlyOccupiedWorkspaceMessage', {
              name: employeeData.fullName,
              workspace: permanentWorkspace.number,
              room: permanentWorkspace.room,
              floor: permanentWorkspace.floor,
              office: `${permanentWorkspace.city}, ${permanentWorkspace.address}`,
            }),
            t('buttons.yes'),
            t('buttons.no'),
          ).subscribe(() => {
            if (workspaceStatus === WorkspaceStatuses.Booked) {
              workspacesApi
                .editWorkspace(permanentWorkspace.id, {
                  employee: null,
                  status: 'Free',
                })
                .subscribe(() => approveRequest(payload));
            } else {
              approveRequest(payload);
            }
          });
        }

        if (workspaceStatus === WorkspaceStatuses.Booked) {
          const temporaryWorkspace = findEmployeeTemporaryWorkspaceOverlaps({
            workspaces: employeeData.workspaces,
            selectedRange: dateRange,
            officeId: office.id,
            workspaceId: seat.id,
          });

          if (temporaryWorkspace) {
            return confirmationModal(
              t('modals.attention'),
              t('modals.employeeHasBookedWorkspaceOnThisDateMessage', {
                name: employeeData.fullName,
                workspace: temporaryWorkspace.number,
                room: temporaryWorkspace.room,
                floor: temporaryWorkspace.floor,
                office: `${temporaryWorkspace.city}, ${temporaryWorkspace.address}`,
                startDate: format(new Date(temporaryWorkspace.startDay), 'dd.MM.yyyy'),
                endDate: format(new Date(temporaryWorkspace.endDay), 'dd.MM.yyyy'),
              }),
              t('buttons.yes'),
              t('buttons.no'),
            ).subscribe(() => {
              workspacesApi
                .deleteBookingWorkspace(temporaryWorkspace.bookingId)
                .subscribe(() => approveRequest(payload));
            });
          }
        }

        approveRequest(payload);
      });
  };

  const rejectRequestHandler = () => {
    rejectRequest(value);
  };

  const commentManagerHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.currentTarget.value);
  };

  const checkStatus = (status: string) =>
    status === 'approved' || status === 'rejected' || status === 'canceled' || status === 'delayed';

  const showRequest = useCallback(() => {
    showOnPlanHandler();
  }, [showOnPlanHandler]);

  const showWorkspace = useCallback(() => {
    showOnPlanHandler({...associatedWorkspace, floorId: floor.id});
  }, [showOnPlanHandler, associatedWorkspace, floor]);

  const isWorkspaceOccupied = associatedWorkspace && associatedWorkspace.status !== WorkspaceStatuses.Free;

  const isWorkspaceTaken = useMemo(() => {
    return checkWorkspaceIsTaken({
      isWorkspaceLoading: isAssociatedWorkspaceLoading,
      workspace: associatedWorkspace,
      workspaceStatus,
      selectedRange,
      userId: user.id,
    });
  }, [associatedWorkspace, isAssociatedWorkspaceLoading, selectedRange, user.id, workspaceStatus]);

  const isApproveBtnDisabled =
    status === 'conflict' ||
    approveInProgress ||
    isAssociatedWorkspaceLoading ||
    isBookingRangeCouldConflictWithAlreadyBookedRanges ||
    !isValid ||
    Object.entries(errors).length > 0 ||
    isWorkspaceTaken;

  const validateWorkspaceStatus = useCallback(
    (status: WorkspaceBookType) => {
      return (status || statusByRequestType[requestType]) === WorkspaceStatuses.Booked;
    },
    [requestType],
  );

  return (
    <Form.Container onSubmit={handleSubmit(approveRequestHandler)}>
      <Styled.SeatRequestContainer>
        {isBookingRangeCouldConflictWithAlreadyBookedRanges && (
          <Styled.ConflictWarningContainer onClick={isWorkspaceOccupied && showWorkspace}>
            <Icon size={16} type="u_exclamation-circle" fill="white" background={theme.palette.state.error.default} />
            <Styled.ConflictWarningText>{t('pages.requests.overlapRangeWarning')}</Styled.ConflictWarningText>
          </Styled.ConflictWarningContainer>
        )}
        {(status === 'conflict' || (isWorkspaceTaken && !isBookingRangeCouldConflictWithAlreadyBookedRanges)) && (
          <Styled.ConflictWarningContainer onClick={isWorkspaceOccupied && showWorkspace}>
            <Icon size={16} type="u_exclamation-circle" fill="white" background={theme.palette.state.error.default} />
            <Styled.ConflictWarningText>{t('pages.requests.conflictWarning')}</Styled.ConflictWarningText>
          </Styled.ConflictWarningContainer>
        )}
        <Styled.PreviewContainer>
          {!personalRequest && (
            <Styled.UserInfo>
              <Styled.UserPhoto size={65} imageUrl={user.photoUrl} hrmId={user.hrm_id} />
              <InformationTile title={t(`pages.requests.requestInitiator`)} value={user.fullName} />
            </Styled.UserInfo>
          )}
          <Styled.StatusTile
            title={t('pages.requests.requestStatus')}
            value={t(`pages.requests.${internationalizeStatus(status)}`)}
            color={color}
            valueIcon={getIconByRequestType(status)}
            iconColor={color}
          />
        </Styled.PreviewContainer>
        <Styled.InformationBlock>
          <Styled.ShowButton onClick={showRequest}>{t('pages.requests.viewOnPlan')}</Styled.ShowButton>
          <Styled.InformationRow>
            <Styled.Tile
              title={t(`pages.requests.${personalRequest ? 'requestId' : 'employeeId'}`)}
              value={personalRequest ? id : user.hrm_id}
            />
            <Styled.Tile title={t('pages.requests.requestFloor')} value={floor?.number} />
          </Styled.InformationRow>
          <Styled.InformationRow>
            <Styled.Tile title={t('pages.requests.requestDate')} value={format(created, 'dd.MM.yyyy')} />
            <Styled.Tile title={t('pages.requests.requestRoom')} value={room?.number} />
          </Styled.InformationRow>
          <Styled.InformationRow>
            <Styled.Tile title={t('pages.requests.requestOffice')} value={office?.address} />
            <Styled.Tile title={t('pages.requests.requestWorkspace')} value={seat?.number} />
          </Styled.InformationRow>
          {(personalRequest || checkStatus(status)) && (
            <Styled.InformationRow>
              <Styled.Tile
                title={range.start ? t('pages.requests.requestStartDate') : t('pages.requests.requestPeriod')}
                value={range.start ? format(range.start, 'dd.MM.yyyy') : t('pages.requests.permanently')}
              />
              <Styled.Tile
                title={range.end && t('pages.requests.requestEndDate')}
                value={range.end && format(range.end, 'dd.MM.yyyy')}
              />
            </Styled.InformationRow>
          )}
        </Styled.InformationBlock>

        {!personalRequest && !checkStatus(status) && (
          <Styled.FormLayout layoutType="horizontal">
            <Controller
              name={SeatRequestFormFields.DateRange}
              control={control}
              defaultValue={[range.start, range.end]}
              rules={{
                validate: (value) =>
                  !validateWorkspaceStatus(watch(SeatRequestFormFields.WorkspaceStatus)) ||
                  value?.every(Boolean) ||
                  t('modals.messages.required'),
              }}
              render={({field: {onChange, value}}) => (
                <Styled.FormFieldContainer>
                  <Form.Field
                    label={`${t('pages.requests.bookingPeriod')}${
                      validateWorkspaceStatus(watch(SeatRequestFormFields.WorkspaceStatus)) ? ' *' : ''
                    }`}
                    error={errors[SeatRequestFormFields.DateRange]?.message}
                  >
                    <CustomDatePicker
                      disabled={!validateWorkspaceStatus(watch(SeatRequestFormFields.WorkspaceStatus))}
                      selectsRange
                      selected={value[0]}
                      startDate={value[0]}
                      endDate={value[1]}
                      onChange={(value: Date | [Date, Date]) => {
                        if (!Array.isArray(value)) return;
                        setFormValue(SeatRequestFormFields.DateRange, value, {
                          shouldValidate: true,
                        });
                      }}
                      placeholderText={t('placeholders.datePicker')}
                      dateFormat="dd/MM/yyyy"
                      minDate={new Date()}
                      maxDate={validateDate.maxInRange(value)}
                      Container={Styled.DatePicker}
                      excludeDateIntervals={disabledForBookingRanges}
                      isHighlighted={isBookingRangeCouldConflictWithAlreadyBookedRanges}
                    />
                  </Form.Field>
                </Styled.FormFieldContainer>
              )}
            />
            <Controller
              name={SeatRequestFormFields.WorkspaceStatus}
              control={control}
              defaultValue={statusByRequestType[requestType]}
              render={({field: {onChange, value}}) => (
                <Styled.FormFieldContainer>
                  <Form.Field
                    label={t('pages.requests.workspaceStatus')}
                    error={errors[SeatRequestFormFields.WorkspaceStatus]}
                  >
                    <Styled.StatusSelect
                      defaultValue={statusByRequestType[requestType]}
                      isVirtual={false}
                      value={value}
                      onValueChange={(value) => {
                        const [start, end] = watch(SeatRequestFormFields.DateRange);
                        if (value !== 'Booked' && ((start && end) || start)) {
                          setFormValue(SeatRequestFormFields.DateRange, [null, null], {shouldValidate: true});
                        }
                        onChange(value);
                        trigger(SeatRequestFormFields.DateRange);
                      }}
                      excludeFree
                      excludeCoworking
                    />
                  </Form.Field>
                </Styled.FormFieldContainer>
              )}
            />
          </Styled.FormLayout>
        )}

        <Styled.InformationRow>
          <Styled.CommentContainer>
            {comment && (
              <>
                <Styled.CommentBlock>
                  <Avatar size={34} imageUrl={user.photoUrl} />
                  <Styled.Comment title={t('pages.requests.requestCommentEmployee')} value={comment} />
                </Styled.CommentBlock>

                <Divider />
              </>
            )}
            {!personalRequest && !checkStatus(status) ? (
              <Styled.CommentBlock>
                <Avatar size={34} imageUrl={person?.photoUrl} />
                <Styled.CommentInput
                  onChange={commentManagerHandler}
                  value={value}
                  placeholder={t('placeholders.yourComment')}
                  maxLength={200}
                />
                {value.length >= 200 && <Styled.Error>{t('pages.requests.lengthError', {number: 200})}</Styled.Error>}
              </Styled.CommentBlock>
            ) : (
              managerComment && (
                <Styled.CommentBlock>
                  <Avatar size={34} imageUrl={officeManager.photoUrl} />
                  <Styled.Comment title={t('pages.requests.requestCommentManager')} value={managerComment} />
                </Styled.CommentBlock>
              )
            )}
          </Styled.CommentContainer>
        </Styled.InformationRow>

        {checkStatus(status) ? (
          <>
            {/* Status in the bottom of seat modal */}
            {/* {status === 'delayed' && (
                  <Styled.MessageBlock>
                  <Styled.Message>{t('pages.requests.canceledRequestMessage')}</Styled.Message>
                  </Styled.MessageBlock>
                  )} */}
            {personalRequest &&
              status !== 'canceled' &&
              status !== 'delayed' &&
              status !== 'rejected' &&
              new Date(range.end).setHours(0, 0, 0, 0) >= new Date().setHours(0, 0, 0, 0) && (
                <Styled.ButtonsContainer>
                  <Styled.ButtonsBlock>
                    <>
                      <Button onClick={editRequestHandler}>{t('buttons.edit')}</Button>
                      <Button variant="outlined" onClick={cancelRequest}>
                        {t('buttons.cancelRequest')}
                      </Button>
                    </>
                  </Styled.ButtonsBlock>
                </Styled.ButtonsContainer>
              )}
          </>
        ) : (
          <Styled.ButtonsContainer>
            <Styled.ButtonsBlock>
              {!personalRequest ? (
                <>
                  <Button type="submit" withLoader={approveInProgress} disabled={isApproveBtnDisabled}>
                    {t('buttons.approve')}
                  </Button>
                  <Button variant="outlined" onClick={rejectRequestHandler}>
                    {t('buttons.reject')}
                  </Button>
                </>
              ) : (
                <>
                  <Button onClick={editRequestHandler}>{t('buttons.edit')}</Button>
                  <Button variant="outlined" onClick={deleteRequest}>
                    {t('buttons.delete')}
                  </Button>
                </>
              )}
            </Styled.ButtonsBlock>
          </Styled.ButtonsContainer>
        )}
      </Styled.SeatRequestContainer>
    </Form.Container>
  );
};

export default withForm(withSeatRequestDetails(withShowOnPlan(SeatRequest)));
